import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, UtilsService } from 'src/app/shared/services';

@Component({
  selector: 'app-chat-prd-preview',
  templateUrl: './chat-prd-preview.component.html',
  styleUrls: ['./chat-prd-preview.component.less']
})
export class ChatPrdPreviewComponent implements OnInit, OnChanges {
@Input() productDetails:any;
@Input() isHandset:boolean;
@Output() navigate = new EventEmitter();
@Output() close = new EventEmitter();
public Math: Math = Math;
slideIndex = 0;

productImages:string[]=[];
  productInfo: any;
  isSingleDimension: boolean;
  dimensionExist: boolean;
  displayable: any;
  product_url: any;
  show_redirect: string;
  sku: any;
  constructor(private api:ApiService,public utils:UtilsService,private router:Router) { }

  get deviceHeight(){
    return  this.isHandset? window.innerHeight-100 :  window.innerHeight-40;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.productDetails.currentValue){ 
      this.productInfo = null;
      this.displayable = this.productDetails.displayable;
      this.product_url = this.productDetails.product_url;
      this.sku = this.productDetails.sku;
      this.api.getProduct(this.productDetails.sku).subscribe(res=>{
        this.productImages = []; 
        this.productInfo = res.product;
        this.show_redirect = this.productInfo.show_redirect;
        this.dimensionExist = this.productInfo.dimension.length > 0;

        this.productImages.push(res.product.main_image);
        this.productInfo.product_images_gallery.forEach(f=>{
        this.productImages.push(f);

        })
        this.isSingleDimension = this.productInfo.dimension.length === 1;
        setTimeout(() => {
          this.currentSlide(1);
        }, 0);
      })

    }
  }
  navigateToPrd(){
    const user = JSON.parse(localStorage.getItem("user"));

    if (this.displayable === "false") {
      
      this.router.navigate([]).then(() => {
      window.open(this.product_url);
    });
    } else if (
      this.displayable === "authorized"&&
      (("" + user.user_type) == "01" ||
        ("" + user.user_type) == "0") &&
      this.show_redirect === 'TRUE'
    ) {
      
      this.router.navigate([]).then(() => {
      window.open(this.product_url);
    });
    } else if (
      this.displayable === "seeded" &&
      (("" + user.user_type) == "0" ||
      ("" + user.user_type) == "01" ||
        ("" + user.user_type) == "02") &&
      this.show_redirect === 'TRUE'
    ) {
      this.router.navigate([]).then(() => {
        window.open(this.product_url);
      });
    } else if (
      (this.displayable === "true" ||
        this.displayable === "restricted" ||
        this.displayable === "preview")  
    ) {
      this.router.navigate([]).then(() => {
        window.open(`/product/${this.sku}`);
      });
    }  else {
      this.router.navigate([]).then(() => {
        window.open(`/product/${this.sku}`);
      });
    }
  }
  get getBtnName():string{
    let name = 'View Details';
    const user = JSON.parse(localStorage.getItem("user"));

    if (this.displayable === "false") {
      name = `View On ${this.productInfo.brand_name} `
    } else if (
      this.displayable === "authorized"&&
      (("" + user.user_type) == "01" ||
        ("" + user.user_type) == "0") &&
      this.show_redirect === 'TRUE'
    ) {
      name = `View On ${this.productInfo.brand_name}`;
    } else if (
      this.displayable === "seeded" &&
      (("" + user.user_type) == "0" ||
      ("" + user.user_type) == "01" ||
        ("" + user.user_type) == "02") &&
      this.show_redirect === 'TRUE'
    ) {
      name = `View On ${this.productInfo.brand_name} %`;
    } else if (
      (this.displayable === "true" ||
        this.displayable === "restricted" ||
        this.displayable === "preview")  
    ) {
    name = 'View Details'
    } 
    return name;
  } 
  
  ngOnInit(): void {
 
  }
  closeSidePanel(){
this.close.emit(true)
  }

  get previewWidth(){
 return   document.getElementById('preview').clientWidth;
  }

  plusSlides(n: number) {
    this.showSlides((this.slideIndex += n));
  }
  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  showSlides(n) {
    let i;
    let slides = document.getElementsByClassName(
      "pSlides"
    ) as HTMLCollectionOf<HTMLElement>;
    if (slides.length > 0) {
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active-dot", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      if(dots && dots[this.slideIndex - 1]){
      dots[this.slideIndex - 1].className += " active-dot";
      }
    }
  }
}
