import { AfterViewInit, Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  ApiService,
  MatDialogUtilsService,
  UtilsService,
} from "./../../../shared/services";
import { NavigationEnd, Router } from "@angular/router";
import { IAllDepartment } from "../../../shared/models/all-department.interface";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { EventEmitterService } from "src/app/shared/services";
import { first } from "rxjs/operators";
import { environment } from "../../../../environments/environment.staging";
import { EnvService } from "src/app/shared/services/env.service";
import { ChatService } from "src/app/shared/services/api/chat.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-nav-mobile",
  templateUrl: "./nav-mobile.component.html",
  styleUrls: ["./nav-mobile.component.less"],
})
export class NavMobileComponent implements OnInit,AfterViewInit {
  @ViewChild("drawer", { static: false }) drawer: any;
  @ViewChild("departmentSideNav", { static: false }) departmentSideNav: any;
  @ViewChild("brandSideNav", { static: false }) brandSideNav: any;
  @ViewChild("collectionSideNav", { static: false }) collectionSideNav: any;
  logoPath = "";
  logoPathWhite = "";
  departments: IAllDepartment[];
  selectedIndex = null;
  menuVisible = false;
  mobileMenuContainer;
  showDepartment;
  showSearchComponent = false;
  @Input() notHome: boolean;
  isBoard: boolean;
  checkHomeRoute: Subscription;
  hideBar = false;
  eventSubscription: Subscription;
  cartProduct: number;
  brands = [];
  collections: any;
  deals = [];
  mobileDeals = [];
  showOffer = false;
  isOfferEmpty = false;
  isFaqPage = false;
  aboutUsPage = false;

  quizResultPage = false;
  finalDeal: any;
  assetBaseUrl = "";
  classList: any;
  showCategory: any;
  filterArray: any[] = [];
  isBrandPage = false;
  navGroup1: any;
  navGroup2: any;
  shopType = ['SHOP FURNITURE','SHOP DECOR'];
  historyList: any[]=[];
  @ViewChild('chatDialog') chatDialog : TemplateRef<any>;
  isToggleChecked=true;

  constructor(
    public apiService: ApiService,
    private utils: UtilsService,
    private cookie: CookieService,
    private router: Router,
    public location: Location,
    private eventEmitterService: EventEmitterService,
    private matDialogUtils: MatDialogUtilsService,
    public env: EnvService,
    private matDialog:MatDialog,
    private chatService:ChatService,
  ) {
    this.logoPath = this.env.logoPathBlack;
    this.logoPathWhite = this.env.logoPathWhite;
    this.assetBaseUrl = this.env.assetBaseUrl;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.menuVisible = false;
      }
    });
    this.checkHomeRoute = router.events.subscribe((val) => {
      // this.notHome = location.path() !== '';

      this.isFaqPage = location.path().match(/faq\-order/) !== null;
      this.notHome =
        location.path() !== "" &&
        // this.location.path().match(/\/design/) == null &&
        location.path().match(/board/) == null &&
        this.location.path().match(/gtm_debug/) === null &&
        this.location.path() !== '/shop' &&//.match(/shop/) === null &&
        this.location.path().match(/ttclid=/) === null &&
        location.path().match(/aboutus/) === null;
      this.isBoard = location.path().match(/board/) !== null;
      this.aboutUsPage = location.path().match(/aboutus/) !== null;
      this.isBrandPage = location.path().match(/brand/) !== null;
      this.showOffer = this.shouldShowOffer(location.path());
    });
  }

  hideIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if(intercom && intercom.classList){
    intercom.classList.add("dn");
    }
  }

  showIntercom() {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app"
    )[0];
    if(intercom && intercom.classList){
      intercom.classList.remove("dn");
      }
  }

  ngOnInit(): void {
    this.quizResultPage = this.router.url.match(/quiz-result/) !== null;
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        this.eventEmitterService.updateCart.subscribe((payload) => {
          // tslint:disable-next-line: radix
          this.cartProduct = parseInt(localStorage.getItem("cart"));
        });
        // tslint:disable-next-line: radix
        this.cartProduct = parseInt(localStorage.getItem("cart"));
        // this.getDepartments();
        // this.getBrands();
        this.getCollections();
        this.router.events.subscribe((res) => {
          const orderRoute = this.router.url.slice(1, 6);
          if (this.router.url === "/checkout" || orderRoute === "order") {
            this.hideBar = true;
          } else {
            this.hideBar = false;
          }
        });
      });
      if(this.isUserLoggedIn){
        this.getAllConverstations();
          }
  }

  openNewsletterPopup() {
    const showNewsLetter =
      (this.location.path() === "" ||
        this.location.path().match(/shop/) !== null ||
        this.location.path().match(/products/) !== null ||
        this.location.path().match(/aboutus/) !== null ||
        this.location.path().match(/product/) !== null) &&
      this.location.path().match(/edit-products/) == null &&
      this.location.path().match(/new-products/) == null &&
      this.location.path().match(/product-intake/) == null  &&
      this.location.path().match(/merchandising/) == null &&
      this.location.path().includes('/design/designer/products/all/') == null;
    const popUpAlreadyShown = this.cookie.get("popupShow");
    if (showNewsLetter && !popUpAlreadyShown && !this.isFirstScroll)  {
      // setTimeout(() => this.matDialogUtils.openNewsLetter(true), 2000);
    }
  }

  private isFirstScroll = true;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.isFirstScroll) {
     // Set the flag to false to avoid executing this code again
      this.isFirstScroll = false;
      this.openNewsletterPopup();
    }
  }

  getDeals() {
    this.finalDeal = [];
    this.apiService
      .getDeals()
      .pipe(first())
      .subscribe((value: any) => {
        this.deals = value.filter((val) => val.is_active);
        this.deals = value.sort((a, b) => {
          return a.rank - b.rank;
        });
        this.mobileDeals = this.deals.filter((deal) => deal.is_mobile);
        this.deals.forEach((f) => {
          if (f.is_landing === "true") {
            this.finalDeal.push(f);
          } else if (f.is_landing === "limited" && this.location.path() == "") {
            this.finalDeal.push(f);
          } else if (f.is_landing === "false" && this.location.path() !== "") {
            this.finalDeal.push(f);
          }
        });
        this.isOfferEmpty = this.mobileDeals.length <= 0;
        this.showOffer = this.shouldShowOffer(this.location.path());
      });
  }
  shouldShowOffer(path: string): boolean {
    return (
      path.match(/checkout/) === null &&
      path.match(/board\/dashboard/) === null &&
      path.match(/blog/) == null &&
      path.match(/quiz-result/) == null &&
      !this.isOfferEmpty
    );
  }
  onDestroy(): void {
    this.checkHomeRoute.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  // getDepartments() {
  //   this.apiService.getAllDepartments().subscribe((payload: any) => {
  //     this.classList = payload.all_departments;
  //     this.navGroup1 = payload.all_departments.filter(f=>f.nav_group===1);
  //         this.navGroup2 = payload.all_departments.filter(f=>f.nav_group===2);
         
  //     const filteredArray = this.classList.flatMap((item) => {
  //       const filteredDepartments = item.departments.filter(
  //         (dept) => dept.displayable === 1
  //       );

  //       const filteredCategories = filteredDepartments.map((dept) => {
  //         const filteredCat = dept.categories.filter(
  //           (cat) => cat.displayable === 1
  //         );
  //         return { ...dept, categories: filteredCat };
  //       });

  //       return { ...item, departments: filteredCategories };
  //     });

  //     // this.filterArray = filteredArray;
    
  //     this.filterArray = [
  //       {
  //         class:'SHOP FURNITURE',
  //         departments:this.navGroup1
  //       },
  //       {
  //         class:'SHOP DECOR',
  //         departments:this.navGroup2
  //       },
  //     ] ;
  //     // this.departments = payload.all_departments;
  //     // this.departments = [];
  //     // payload.all_departments.forEach((val) => {
  //     //     this.departments = [...this.departments, ...val.departments];
  //     // });
  //   });
  // }
  goToBlack() {
    this.router.navigate(["/products/sale/blackfriday?sortType=recommended"]);
    this.closeAll();
  }
  get isContact() {
    return this.location.path().match(/contact/);
  }
  get isDesign() {
    return (
      // this.location.path().match(/design/) &&
      this.location.path().match(/gpt/) == null
    );
  }
  get isDesignSign() {
    return  this.location.path() ==='/design/book/sign-up' 
   || this.location.path().includes('design/book/project-details')
   || this.location.path().includes('design/book/payment') ;
  }
  get isDesignPage(){
    return this.location.path() === '/design' || 
    (this.location.path().includes('/design') && this.location.path().includes('utm'));
  }
  get isShop() {
    return this.location.path() === 'shop';
  }
  getBrands() {
    this.apiService.getBrands().subscribe((payload: any) => {
      this.brands = payload; 
      this.brands.sort((a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();
        return nameA.localeCompare(nameB);
      });
    });
  }

  getCollections() {
    this.apiService.getCollections().subscribe((payload: any) => {
      this.collections = payload;
    });
  }

  showMenu() {
    this.menuVisible = !this.menuVisible;
  }

  showDepartmentChild(dept) {
    if (dept === this.showDepartment) {
      this.showDepartment = undefined;
      return;
    }
    this.showDepartment = dept;
  }

  showCategoryChild(cat) {
    if (cat === this.showCategory) {
      this.showCategory = undefined;
      return;
    }
    this.showCategory = cat;
  }

  showSearchBar() {
    this.showSearchComponent = !this.showSearchComponent;
  }

  onSearchComplete(e) {
    this.showSearchComponent = false;
  }

  isLoggedIn(event) {
    event.preventDefault();
    event.stopPropagation();

    const localData = JSON.parse(localStorage.getItem("user") || "{}");
    if (localData.email.length > 0) {
      this.router.navigateByUrl("/wishlist");
    } else {
      this.matDialogUtils.openSignupDialog(true);
    }
  }

  get isHomePage(){
    return this.location.path() === "" || (this.location.path().includes('utm_source=')
 && !this.location.path().startsWith('/') );
  }
  
  toNewArrivalsPage() {
    this.router.navigate(["/products/all"], { queryParams: { new: true } });
    this.closeAll();
  }

  toTopDealsPage() {
    this.router.navigate(["/products/all"], { queryParams: { sale: true } });
    this.closeAll();
  }

  toBrandPage(brand) {
    // this.router.navigate(["/brand"], {
    //   queryParams: { filters: `brand:${brand.value};` },
    // });
    this.router.navigateByUrl(`/brand/${brand.value}`);
    this.closeAll();
  }

  toCollectionPage(collection) {
    this.router.navigate(["/products/collections"], {
      queryParams: { filters: `collection:${collection.value};` },
    });
    this.closeAll();
  }

  closeAll() {
    this.drawer.close();
    this.departmentSideNav.close();
    this.brandSideNav.close();
    this.collectionSideNav.close();
  }

  openOfferModal() {
    this.matDialogUtils.openAllOffersDialog(this.finalDeal);
  }
  newSearch(){
    this.router.navigate(["/chat"]);
    this.closeAll();
  }
  newDesign(){
    this.router.navigate(["/design"]);
    this.closeAll();
  }
  sale(){
    this.router.navigate(["/sale"]);
    this.closeAll();
  }
  goToNew(){
    this.router.navigate(["products/new"]);
    this.closeAll();
  }
  openSignupDialog() {
    this.matDialogUtils.openSignupDialog(true);
  }

  openSignInDialog() {
    this.matDialogUtils.openSigninDialog(true ? "90%" : "35%");
  }
  savedSearches(){
    // this.router.navigate(["/saved-searches"]);
    this.closeAll();
  } 
  get isOnBoard(){
    return this.location.path().includes('/style-quiz');
  }

  get isUserLoggedIn(): boolean {
    if( JSON.parse(localStorage.getItem("user"))){
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
      } else{
        return false;
      }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      
    this.getDeals(); 
    }, 2500);
  }
  
  openQuiz(f,i){
    if(i===0){
    this.apiService.quizCategory = f.class_url.split('/')[1];
    this.router.navigateByUrl(`style-quiz`);
    }else {
      this.router.navigateByUrl(`shop/${f.class_url}`);
    }
    this.closeAll();
  }
  openSaleNew(type){
    this.router.navigateByUrl(`products/${type}`);
    this.closeAll();
  }

  getAllConverstations() {
    this.chatService.getUserConvSummary().subscribe((resp: any[]) => {
      this.historyList = resp;
      // this.groupByDate();
      // if (this.conversation_id) {
      //   const chatData = this.historyList.find(
      //     (f) => f.conversation_id === this.conversation_id
      //   );
      //   if (chatData) {
      //     this.assignChatData(chatData);
      //   }
      // }
    });
  }
  goToConv(h){
    this.router.navigate([`/c/${h.conversation_id}`])
  }
  goTo(url){
    if(url){
      this.router.navigate([]).then(() => {
        window.open(`${url}`);
      });
    }
  }
  get isProductPage() {
    return this.location.path().split('/')[1] === "products" || this.location.path().split('/')[1] === "product";
    // return this.location.path().split('/')[1] === "product";
  } 
  openFullDialog(){
    this.matDialog.open(this.chatDialog,{
      width:'350px',
      panelClass: 'chat-dialog-container' 
    })
  }
  query='';
  assignQuery() {
    sessionStorage.removeItem('promptCategory'); 
    this.chatService.promptCategory='';
    this.chatService.productsDataPLP = [];
    this.matDialog.closeAll();
    if (this.isToggleChecked) {
      this.chatService.query = this.query;
      this.router.navigate(["/chat"]);
    } else { 
      const data = `?query=${this.query}`
      this.router.navigateByUrl(`/search${data}`).then(() => {
        this.query = "";
      });
    }
  }
}