import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { IAllDepartment } from "../../../shared/models";
import {
  ApiService,
  MatDialogUtilsService,
  UtilsService,
} from "./../../../shared/services";
import { MessageService } from "primeng/api";
import { CookieService } from "ngx-cookie-service";
import { EventEmitterService } from "src/app/shared/services";
import { first } from "rxjs/operators";
import { EnvService } from "../../../shared/services/env.service";
import { ChatService } from "src/app/shared/services/api/chat.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-nav-desktop",
  templateUrl: "./nav-desktop.component.html",
  styleUrls: ["./nav-desktop.component.less"],
})
export class NavDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("shop", { static: false }) shop: ElementRef<any>;
  @ViewChild("design", { static: false }) design: ElementRef<any>;
  @ViewChild("searches", { static: false }) searches: ElementRef<any>;
  @ViewChild("new", { static: false }) new: ElementRef<any>;
  @ViewChild("target", { static: false }) target: ElementRef<any>;
  @Input() isTablet: boolean;
  logoPath = "";
  departments: IAllDepartment[] | any[];
  @Input() notHome: boolean;
  checkHomeRoute: Subscription;
  eventSubscription: Subscription;
  email: any;
  password: any;
  hideBar = false;
  cartProduct: number;
  isFaqPage = false;
  isBrandPage = false;
  params: any;
  deals = [];
  showOffer = false;
  //
  isShop = true;
  isDesign = false;
  aboutUsPage = false;
  quizResultPage = false;
  data = "";
  finalDeal: any[];
  isAdminDashboard: boolean;
  isPreview: boolean;
  isGptFinal: boolean;
  isGpt: boolean;
  classList: any[]=[];
  navGroup1: any;
  navGroup2: any;
  skeletonLoader: boolean;
  historyList: any[]=[];
  timedOutCloser:any;
  @ViewChild('chatDialog') chatDialog : TemplateRef<any>;
  isToggleChecked=true;
  constructor(
    public router: Router,
    public location: Location,
    private utils: UtilsService,
    public apiService: ApiService,
    private messageService: MessageService,
    private cookie: CookieService, 
    public eventEmitterService: EventEmitterService,
    private matDialogUtils: MatDialogUtilsService,
    private activeRoute: ActivatedRoute,
    public env: EnvService, 
    private matDialog:MatDialog,
    private chatService:ChatService,
  ) {
    this.logoPath = this.env.logoPathBlack;
    this.checkHomeRoute = router.events.subscribe((val) => {
      this.setContext();
    });
    this.setContext();
  }

    get isUserLoggedIn(): boolean {
      if( JSON.parse(localStorage.getItem("user"))){
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
      } else{
        return false;
      }
    }

  setContext() { 
    this.isFaqPage =
      this.location.path().match(/faq\-order/) !== null ||
      this.location.path().match(/furniture\-care/) !== null;
    this.notHome =
      this.location.path() !== "" &&
      // this.location.path().match(/\/design/) == null &&
      this.location.path().match(/shop/) === null &&
    
      this.location.path().match(/blog/) == null &&
      this.location.path().match(/faq\-order/) == null &&
      this.location.path().match(/furniture\-care/) == null &&
      this.location.path().match(/aboutus/) === null &&
      // this.location.path().match(/contact/) === null &&
      this.location.path().match(/gtm_debug/) === null &&
      this.location.path().match(/ttclid=/) === null &&
      // this.location.path().match(/preview/) === null &&
      this.location.path().match(/seller/) === null;

    this.aboutUsPage = this.location.path().match(/aboutus/) !== null;
    this.quizResultPage = this.location.path().match(/quiz-result/) !== null;
    this.showOffer = this.shouldShowOffer(this.location.path());

    this.isShop = this.location.path().match(/shop/) !== null ;
    this.isAdminDashboard =
     ( this.location.path().match(/admin/) !== null &&
      this.location.path().match(/dashboard/) !== null  ||
      this.location.path().includes('design/designer'))
      ;

    this.isPreview = this.location.path().match(/preview/) !== null;
    this.isDesign =
      this.location.path() === "/design/gpt" ||
      this.location.path() === "/design/gpt/renders";
    this.isGptFinal =
      !this.location.path().match(/gpt/) &&
      this.location.path().split("-").length < 1;
    this.isGpt =
      this.location.path().match(/gpt/) &&
      this.location.path().split("-").length > 1;
    this.hideBar =
      this.router.url === "/aboutus" || this.router.url === "/checkout" ||
      this.router.url.includes("/design/book/payment") 
      ;

      
  }
  get isNotShop() {
    return this.location.path().includes("shop/products");
  }
  get onlyShop() {
    return this.location.path().includes("shop") && !this.location.path().includes("products");
  }

  get onlySale() {
    return this.location.path().includes("sale");
  }

  get onlyNew() {
    return this.location.path().includes("new");
  }

  get isStyleQuiz() {
    return this.location.path() === "/style-quiz";
  }

  get isHomePage(){
    return this.location.path() === "" || (this.location.path().includes('utm_source=')
 && !this.location.path().startsWith('/') );
  }

  ngOnInit(): void { 
    this.eventSubscription = this.eventEmitterService.userChangeEvent
      .asObservable()
      .subscribe((user) => {
        this.eventEmitterService.updateCart.subscribe((payload) => {
          // tslint:disable-next-line: radix
          this.cartProduct = parseInt(localStorage.getItem("cart"));
        });
        // tslint:disable-next-line: radix
        this.cartProduct = parseInt(localStorage.getItem("cart"));
        // this.getDepartments();
        this.router.events.subscribe((res) => {
          const orderRoute = this.router.url.match(/order\/.*/) !== null;
          this.hideBar =
            this.router.url === "/aboutus" ||
            this.router.url === "/checkout" ||
            this.router.url.includes("/design/book/payment") 

            orderRoute;
        });
      });

      // document.addEventListener('DOMContentLoaded', () => {
      
      //   const fixedSection = document.querySelector('.lpFixed') as HTMLElement;
      //   if(fixedSection){
      //   const initialOffset = fixedSection.offsetTop;
      
      //   window.addEventListener('scroll', () => {
      //     if(this.isHomePage){
      //     if (window.pageYOffset >= 50) {
      //       fixedSection.classList.add('fixed');
      //     } else {
      //       fixedSection.classList.remove('fixed');
      //  }      }
      //   });
      // }
      // });
      

    this.eventEmitterService.isBrandSubject.subscribe((brandValue: string) => {
      // this.getDepartments(brandValue);
    });

    this.activeRoute.queryParams.subscribe((params) => {
      // this.isClearAllVisible = params.filters !== '';
      this.params = params;
    });
    // this.loadNewsLetterPopup();
  }
  goToConv(h){
    this.router.navigate([`/c/${h.conversation_id}`])
  }
  getAllConverstations() {
    this.chatService.getUserConvSummary().subscribe((resp: any) => {
      this.historyList  = resp;
      // this.groupByDate();
      // if (this.conversation_id) {
      //   const chatData = this.historyList.find(
      //     (f) => f.conversation_id === this.conversation_id
      //   );
      //   if (chatData) {
      //     this.assignChatData(chatData);
      //   }
      // }
    });
  }


   
  @HostListener("window:wheel", ["$event"])
  onWindowScroll() {
    const fixedSection = document.querySelector('.lpFixed') as HTMLElement;
    if(fixedSection){
    const initialOffset = fixedSection.offsetTop;
   
      if (window.pageYOffset >= 50 && !this.isHomePage) {
        fixedSection.classList.add('fixed');
      } else {
        fixedSection.classList.remove('fixed');
      } 
    }
  }

  ngOnDestroy(): void {
    const fixedSection = document.querySelector('.lpFixed') as HTMLElement;  
    if(fixedSection){
        fixedSection.classList.remove('fixed'); 
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => { 
      if(this.isUserLoggedIn){
    this.getAllConverstations();
      }
    this.getDeals();
    }, 2500);
    this.initializeNavbarHover();
    this.setStyle();
    if (this.isShop) {
      this.setLink(1);
    }
  }

  getDeals() {
    this.finalDeal = [];
    this.apiService
      .getDeals()
      .pipe(first())
      .subscribe((value: any) => {
        this.deals = value.sort((a, b) => {
          return a.rank - b.rank;
        });
        this.deals = this.deals.filter((v) => {
          return v.is_active === 1;
        });
        this.showOffer = this.shouldShowOffer(this.location.path());
        this.deals.forEach((f) => {
          if (f.is_landing === "true") {
            this.finalDeal.push(f);
          } else if (f.is_landing === "limited" && this.location.path() == "") {
            this.finalDeal.push(f);
          } else if (f.is_landing === "false" && this.location.path() !== "") {
            this.finalDeal.push(f);
          }
        });
      });
  }

  shouldShowOffer(path: string): boolean {
    return (
      path.match(/checkout/) === null &&
      path.match(/board/) === null &&
      path.match(/blog/) == null &&
      path.match(/seller/) == null &&
      path.match(/quiz-result/) == null &&
      path.match(/design/) == null &&
      path.match(/gpt/) == null &&
      this.deals &&
      this.deals.length >= 1
    );
  }

  initializeNavbarHover() {
    const target = document.querySelector(".target");
    const links = document.querySelectorAll(".my-boards-link");
  }

  setLink(link) {
    let width, height, left;
    let parentLeft;
   
    if (link === 0) {
      parentLeft =
        this.shop.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.shop.nativeElement.getBoundingClientRect().width;
      height = this.shop.nativeElement.getBoundingClientRect().height;
      left = this.shop.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.remove("active-nav-link");
      this.design.nativeElement.classList.remove("active-nav-link");
    this.searches && this.searches.nativeElement?  this.searches.nativeElement.classList.remove("active-nav-link"):null;
    }else if (link === 1) {
      parentLeft =
        this.shop.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.shop.nativeElement.getBoundingClientRect().width;
      height = this.shop.nativeElement.getBoundingClientRect().height;
      left = this.shop.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.add("active-nav-link");
      this.design.nativeElement.classList.remove("active-nav-link");
    this.searches && this.searches.nativeElement?  this.searches.nativeElement.classList.remove("active-nav-link"):null;
    } else if(link ===2){
      parentLeft =
      this.design.nativeElement.parentElement.getBoundingClientRect().left;
    width = this.design.nativeElement.getBoundingClientRect().width;
    height = this.design.nativeElement.getBoundingClientRect().height;
    left =
      this.design.nativeElement.getBoundingClientRect().left - parentLeft;
    this.shop.nativeElement.classList.remove("active-nav-link");

    this.searches && this.searches.nativeElement?  this.searches.nativeElement.classList.remove("active-nav-link"):null;
    this.design.nativeElement.classList.add("active-nav-link");
    } else if(link ===3) {
      parentLeft =
        this.searches.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.searches.nativeElement.getBoundingClientRect().width;
      height = this.searches.nativeElement.getBoundingClientRect().height;
      left =
        this.searches.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.remove("active-nav-link");
      this.design.nativeElement.classList.remove("active-nav-link");
      this.searches.nativeElement.classList.add("active-nav-link");
    } else if(link ===4) {
      parentLeft =
        this.new.nativeElement.parentElement.getBoundingClientRect().left;
      width = this.new.nativeElement.getBoundingClientRect().width;
      height = this.new.nativeElement.getBoundingClientRect().height;
      left =
        this.new.nativeElement.getBoundingClientRect().left - parentLeft;
      this.shop.nativeElement.classList.remove("active-nav-link");
      this.design.nativeElement.classList.remove("active-nav-link");
      this.searches.nativeElement.classList.remove("active-nav-link");
      this.new.nativeElement.classList.add("active-nav-link");
    }
    if(this.target && this.target.nativeElement){
    this.target.nativeElement.style.width = `${width}px`;
    this.target.nativeElement.style.height = `${height}px`;
    this.target.nativeElement.style.left = `${left}px`;
    // this.target.nativeElement.style.top = `${top}px`;
    this.target.nativeElement.style.transform = "none";
    }
  }

  loadNewsLetterPopup() {
    const showNewsLetter =
    // this.location.path() === "" ||
      (
        this.location.path().match(/shop/) !== null ||
        this.location.path().match(/aboutus/) !== null ||
        this.location.path().match(/product/) !== null) &&
      this.location.path().match(/edit-products/) == null &&
      this.location.path().match(/new-products/) == null &&
      this.location.path().match(/product-intake/) == null  &&
      this.location.path().match(/merchandising/) == null &&
      this.location.path().includes('/design/designer/products/all/') == null;
    const popUpAlreadyShown = this.cookie.get("popupShow");
    if (showNewsLetter && !popUpAlreadyShown && !this.isFirstScroll)  {
      setTimeout(() => this.matDialogUtils.openNewsLetter(), 2000);
    }
  }

  private isFirstScroll = true;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.isFirstScroll) {

     // Set the flag to false to avoid executing this code again
      this.isFirstScroll = false;
      this.loadNewsLetterPopup();
    }
  }

  get isCart() {
    return this.location.path().match(/cart/);
  }
  get isContact() {
    return this.location.path().match(/contact/);
  }
  get isLanding() {
    return this.location.path().match(/landing/);
  }
  get isGPTPage() {
    return (
      // this.location.path().match(/design/) == null &&
      this.location.path().match(/gpt/) == null
    );
  }
  get isDesignPage() {
    return (this.location.path() === "/design" ||  
    (this.location.path().includes('/design') && this.location.path().includes('utm'))) && !this.location.path().includes('projects') 
     && !this.location.path().includes('/landing') 
  }
 
  get isDesignProjectPage() {
    return this.location.path() === "/design/projects"
  }
  get isProductPage() {
    return this.location.path().split('/')[1] === "products" || this.location.path().split('/')[1] === "product";
    // return this.location.path().split('/')[1] === "product";
  } 
  get isQuizProductPage() {
    // return this.location.path().split('/')[1] === "products" || this.location.path().split('/')[1] === "product";
    return this.location.path().includes('products/custom');

  }
  // get isShop() {
  //     return this.location.path().match(/design/) ;
  // }

  onDestroy(): void {
    this.checkHomeRoute.unsubscribe();
    this.eventSubscription.unsubscribe();
  }

  login() {
    const user = {
      email: this.email,
      password: this.password,
    };
    if (user.email && user.password) {
      this.apiService.login(user).subscribe((res) => {
        // tslint:disable-next-line: no-string-literal
        if (res["data"]) {
          // this.apiService.storeUserData(res["token"]);
          // localStorage.setItem("admin_id", res["data"]["id"]);
          // localStorage.setItem("role", res["data"]["role"]);
          // this.router.navigate(["admin"]);
          this.messageService.add({
            severity: "success",
            summary: "Success Message",
            detail: "User logged in",
          });
        }
      });
      // .catch(err => {
      //   this.messageService.add({
      //     severity: "error",
      //     summary: " Error Message",
      //     detail: "Invalid Email or Password"
      //   });
      // });
    } else {
      this.messageService.add({
        severity: "error",
        summary: " Error Message",
        detail: "Please Enter Email and Password",
      });
    }
  }

   

  isLoggedIn(event) {
    event.preventDefault();
    event.stopPropagation();
    const localData = JSON.parse(localStorage.getItem("user") || "{}");
    if (localData.email.length > 0) {
      this.router.navigateByUrl("/wishlist");
    } else {
      this.matDialogUtils.openSignupDialog(false);
    }
  }

  // getDepartments(brandValue: string = "") {
  //   // flag value to show nav bar in brand page
  //   let brandValueForDepart = "";
  //   if (brandValue === "" || brandValue === undefined) {
  //     this.isBrandPage = false;
  //     brandValueForDepart = "";
  //   } else {
  //     this.isBrandPage = true;
  //     brandValueForDepart = brandValue;
  //   }
  //   if (this.classList.length === 0) {
  //     this.skeletonLoader = true;
  //     this.apiService
  //       .getAllDepartments(brandValueForDepart)
  //       .subscribe((payload: any) => {
  //         // this.departments = payload.all_departments;
  //         this.classList = payload.all_departments;

  //         this.navGroup1 = payload.all_departments.filter(f=>f.nav_group===1);
  //         this.navGroup2 = payload.all_departments.filter(f=>f.nav_group===2);
  //         this.skeletonLoader = false; 

  //         // this.departments = [];
  //         // payload.all_departments.forEach((val) => {
  //         //     this.departments = [...this.departments, ...val.departments];
  //         // });
  //       });
  //   }
  // }

  setCategoryForBrandPage(categoryValue: number) {
    let newParams: any = this.params;
    let filterValue: string = newParams.filters || "";
    const checkCategoryPos = filterValue.indexOf("category:");
    if (checkCategoryPos < 0) {
      filterValue += `category:${categoryValue};`;
    } else {
      const restString = filterValue.slice(checkCategoryPos + 9);
      const endBrandPos = restString.indexOf(";");
      const subCategoryString = filterValue.substr(
        checkCategoryPos,
        endBrandPos + checkCategoryPos
      );
      filterValue = filterValue.replace(
        subCategoryString,
        `category:${categoryValue};`
      );
    }
    const resultFilter = {
      ...newParams,
      ...{ filters: filterValue },
    };
    // this.router.navigate(["products/brand"], { queryParams: resultFilter });
    this.router.navigate(["/brand"], { queryParams: resultFilter });
  }

  openOfferModal() {
    this.matDialogUtils.openAllOffersDialog(this.finalDeal);
  }
  openSearch(): void {
    if (this.data.length > 0) {
      this.router.navigateByUrl(`/search?query=${this.data}`).then(() => {
        this.data = "";
        // this.searchComplete.emit();
      });
    } else {
      // this.textChange = true;
    }
  }

  setStyle() {
    const cols = document.getElementsByClassName("dropdown-content") as any; // this.el.nativeElement.querySelectorAll('dropdown-content');
    if (cols) {
      const windowWidth = window.innerWidth; 
      let left;
      let width;
      if (windowWidth > 1800 && windowWidth <= 6500) {
        left = 20;
        width = 1800;
        const increments = Math.floor((windowWidth - 1800) / 2); 
        // Adjust the left value for every 2-point increment
        left += increments;
      }
      if (left && width) {
        for (let i = 0; i < cols.length; i++) {
          cols[i].style.left = left + "px";
          cols[i].style.width = width + "px";
        }
      } 
    }
  }
  fillCols(count){ 
    if(count>4){
    let cols =[];
    let mod = count%4;
    let add = 4-mod;
    for(let i=0;i<add;i++){
      cols.push(i);
    }
    return cols;
  }
  }
 get showCatNavBar(){
return this.classList && this.classList.length>0 && this.utils.showCatNav
  }
  openProduct(f){
    // this.router.navigateByUrl(`products/${f.split('products/')[0]}`);
    // this.router.navigateByUrl(`shop/${f}`);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
  }
  this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/${f}`]);
    // window.location.reload();
  }
  openQuiz(f){
    this.apiService.quizCategory = f.class_url.split('/')[1];
    this.router.navigateByUrl(`style-quiz`);
  }
  get designProjectId(){
    if(this.location.path().includes('design/project') &&  !this.location.path().includes('/landing')  &&  this.router.url.match(/\d+/g) ){
      let ids = this.router.url.match(/\d+/g);
       return ids[0];
     
    } else{
      return null;
    }
  }
  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }
  goTo(url){
    if(url){
    // this.router.navigateByUrl(`${url}`);
    this.router.navigate([]).then(() => {
      window.open(`${url}`);
    });
    }
  }
  openFullDialog(){
    this.matDialog.open(this.chatDialog,{
      width:'800px',
      panelClass: 'custom-dialog-container' 
    })
  }
  query='';
  assignQuery() {
    sessionStorage.removeItem('promptCategory'); 
    this.chatService.productsDataPLP = [];
    this.chatService.promptCategory='';
    this.matDialog.closeAll();
    if (this.isToggleChecked) {
      this.chatService.query = this.query;
      this.router.navigate(["/chat"]);
    } else { 
      const data = `?query=${this.query}`
      this.router.navigateByUrl(`/search${data}`).then(() => {
        this.query = "";
      });
    }
  }
}